.footer {
  background-color: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}
.firstPart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.logof {
  display: flex;
  align-items: center;
  color: #c89116;
  gap: 1rem;
}
.logof img {
  width: 7rem;
}
.logof h3 {
  color: white;
  font-weight: lighter;
}
.cert {
  font-weight: lighter;
}
.firstl,
.secondl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.secondl {
  align-items: baseline;
  gap: 0.5rem;
}
.secondl h1 {
  font-size: 2.4rem;
  font-weight: 500;
  background: linear-gradient(0deg, #c89116 0%, #422e00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.linethree {
  width: 100%;
  border: 2px solid #c89116;
  border-radius: 0.1rem;
}
.partner {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 1rem;
}
.onelinel {
  display: flex;
  align-items: center;
}
.onelinel img {
  width: 6rem;
}
.footnav {
  margin-bottom: 1rem;
}
.navLinkF {
  padding: 0rem 1.1rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  opacity: 0.8;
}
.navLinkF:active,
.navLinkF:hover,
.navLinkF:focus {
  opacity: 1;
  font-weight: bold;
}
.line {
  border: 1px solid white;
  width: 100%;
}
.copyright {
  opacity: 0.7;
  color: white;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.copyright a {
  text-decoration: none;
  color: white;
  transition: all 0.8s ease-in-out;
}
.copyright a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1286px) {
}

@media only screen and (max-width: 1024px) {
  .logof img {
    width: 6rem;
  }
  .logof h3 {
    font-size: 1rem;
  }
  .secondl h1 {
    font-size: 2rem;
  }
  .onelinel img {
    width: 5rem;
  }
  .cert {
    font-size: 0.9rem;
  }
}

@media (max-width: 930px) {
  .linethree {
    border: 1.5px solid #c89116;
  }
  .logof img {
    width: 5rem;
  }
  .logof h3 {
    font-size: 0.9rem;
  }
  .secondl h1 {
    font-size: 1.8rem;
  }
  .onelinel img {
    width: 4rem;
  }
  .cert {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  .navLinkF {
    font-size: 0.9rem;
  }
  .logof img {
    width: 4rem;
  }
  .logof h3 {
    font-size: 0.8rem;
  }
  .secondl h1 {
    font-size: 1.7rem;
  }
  .onelinel img {
    width: 3.5rem;
  }
  .cert {
    font-size: 0.75rem;
  }
  .copyright {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 700px) {
}

@media only screen and (max-width: 616px) {
  .navLinkF {
    font-size: 0.8rem;
  }
  .logof img {
    width: 3.3rem;
  }
  .logof h3 {
    font-size: 0.7rem;
  }
  .secondl h1 {
    font-size: 1.4rem;
  }
  .onelinel img {
    width: 3rem;
  }
  .cert {
    font-size: 0.7rem;
  }
  .copyright {
    font-size: 0.85rem;
  }
  .line {
    border: 0.7px solid white;
  }
}
@media only screen and (max-width: 535px) {
  .copyright {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 480px) {
  .navLinkF {
    font-size: 0.7rem;
    padding: 0rem 0.8rem;
  }
  .logof img {
    width: 2.5rem;
  }
  .logof h3 {
    font-size: 0.5rem;
    font-weight: 300;
  }
  .secondl h1 {
    font-size: 1rem;
  }
  .onelinel img {
    width: 2rem;
  }
  .cert {
    font-size: 0.57rem;
    font-weight: 300;
  }
  .copyright {
    flex-direction: column;
    gap: 1rem;
  }
  .logof {
    gap: 0.5rem;
  }
}

@media (max-width: 420px) {
  .navLinkF {
    padding: 0rem 0.7rem;
  }
  .logof img {
    width: 2.2rem;
  }
  .logof h3 {
    font-size: 0.4rem;
  }
  .secondl h1 {
    font-size: 0.8rem;
  }
  .onelinel img {
    width: 1.8rem;
  }
  .cert {
    font-size: 0.54rem;
  }
  .copyright {
    font-size: 0.7rem;
  }
  .logof {
    gap: 0.4rem;
  }
  .line {
    border: 0.5px solid white;
  }
}

@media (max-width: 391px) {
}

@media screen and (max-width: 360px) {
}

@media (max-width: 350px) {
  .navLinkF {
    padding: 0rem 0.5rem;
  }
  .logof img {
    width: 2rem;
  }
  .logof h3 {
    font-size: 0.35rem;
  }
  .secondl h1 {
    font-size: 0.7rem;
  }
  .cert {
    font-size: 0.3rem;
  }
  .copyright {
    font-size: 0.5rem;
  }
  .logof {
    gap: 0.2rem;
  }
}

@media (max-width: 320px) {
}
