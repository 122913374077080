.hero {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
  z-index: 3;
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  opacity: 0.9;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url("../../assets/images/bgone.webp");
  background-size: cover;
  background-position: 50% 25%;
  color: white;
}
.heading {
  color: white;
  font-size: 4.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15rem;
}
.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
  margin-bottom: 4rem;
}
.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  font-size: 2rem;
}
.textgradient {
  font-size: 3rem;
  background: linear-gradient(180deg, #ffc978 0%, #997948 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.careers,
.overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  gap: 0.7rem;
}
h1,
h3 {
  font-weight: 400;
}
.careers h1,
.overview h1 {
  font-size: 3rem;
  text-transform: uppercase;
}
.overview {
  text-align: center;
  background: linear-gradient(90deg, #d9d9d9 0%, #737373 100%);
  color: #000000;
}
.overview p {
  font-size: 1.5rem;
  text-align: center;
}
.overview h1 {
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (max-width: 1286px) {
  .heading {
    font-size: 4.4rem;
  }
}

@media only screen and (max-width: 1024px) {
  .heading {
    font-size: 3.7rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 2.8rem;
  }
  .overview p {
    font-size: 1.3rem;
  }
  .desc {
    font-size: 1rem;
  }
}

@media (max-width: 930px) {
  .heading {
    font-size: 3.3rem;
  }
  .careers h3 {
    font-size: 1rem;
  }
  .desc {
    font-size: 0.8rem;
  }
  .careers h2 {
    font-size: 1.3rem;
  }
  .overview p {
    font-size: 1.1rem;
  }
  .remote h3 {
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 2.8rem;
    padding-bottom: 18rem;
  }
  .box {
    flex-direction: column;
    gap: 1rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 2.4rem;
  }
  .overview p {
    font-size: 1rem;
    text-align: justify;
  }
  .careers h3 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 700px) {
  .heading {
    font-size: 2.5rem;
  }
  .careers h3 {
    font-size: 0.8rem;
  }
  .desc {
    font-size: 0.7rem;
  }
  .apply {
    font-size: 0.9rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 2.2rem;
  }
  .overview p {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 616px) {
  .heading {
    font-size: 2rem;
    padding-bottom: 19rem;
  }
  .star i {
    font-size: 0.9rem;
  }
  .careers h3 {
    font-size: 0.75rem;
  }
  .careers h2 {
    font-size: 1rem;
  }
  .overview p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
    padding-bottom: 20rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 1.9rem;
  }
  .star i {
    font-size: 0.7rem;
  }
}

@media (max-width: 420px) {
  .heading {
    font-size: 1.2rem;
    padding-bottom: 21rem;
  }
  .overview {
    padding: 1.5rem 3rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 1.7rem;
  }
}

@media (max-width: 391px) {
  .heading {
    font-size: 1rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 1.5rem;
  }
  .overview p {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 360px) {
  .apply {
    font-size: 0.7rem;
  }
  .desc {
    font-size: 0.5rem;
  }
  .careers h3 {
    font-size: 0.65rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 1.3rem;
  }
  .careers h2 {
    font-size: 0.8rem;
  }
  .star i {
    font-size: 0.5rem;
  }
  .careers,
  .overview {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 350px) {
}

@media (max-width: 320px) {
  .careers h3 {
    font-size: 0.55rem;
  }
  .overview p {
    font-size: 0.5rem;
  }
  .careers h1,
  .overview h1 {
    font-size: 1rem;
  }
  .apply {
    font-size: 0.5rem;
  }
}
