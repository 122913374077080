.service {
  padding: 3rem 3rem 5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.service h1 {
  font-size: 3rem;
  text-transform: uppercase;
}
.service li {
  list-style-type: circle;
  font-size: 1.5rem;
}
.displ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
}
.displ img {
  border-radius: 0.2rem;
  width: 20rem;
}
.first,
.second,
.three {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  height: 80vh;
}
.manu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.manu ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.manu li {
  color: white;
  font-size: 1.2rem;
}
.manu p {
  font-size: 1.5rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 1286px) {
  .displ img {
    width: 15rem;
  }
}

@media only screen and (max-width: 1107px) {
  .displ img {
    width: 13rem;
  }
}

@media (max-width: 1024px) {
  .displ img {
    width: 12rem;
  }
  .service h1 {
    font-size: 2rem;
  }
  .manu li {
    font-size: 1.1rem;
  }
  .manu p {
    font-size: 1.3rem;
  }
  .service li {
    font-size: 1.3rem;
  }
  .first,
  .second,
  .three {
    gap: 0.7rem;
  }
}

@media only screen and (max-width: 908px) {
  .displ img {
    width: 11rem;
  }
  .service li {
    font-size: 1.1rem;
  }
  .manu li {
    font-size: 1rem;
  }
  .manu p {
    font-size: 1.2rem;
  }
  .first,
  .second,
  .three {
    height: 100%;
  }
}

@media screen and (max-width: 845px) {
  .displ img {
    width: 10rem;
  }
}

@media only screen and (max-width: 780px) {
  .displ img {
    width: 9rem;
  }
  .service {
    gap: 3rem;
  }
  .service li {
    font-size: 1rem;
  }
  .manu p {
    font-size: 1rem;
  }
  .manu li {
    font-size: 0.95rem;
  }
  .service h1 {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 720px) {
  .displ img {
    width: 8.5rem;
  }
}
@media only screen and (max-width: 716px) {
  .displ img {
    width: 8rem;
  }
  .service li {
    font-size: 0.85rem;
  }
}
@media only screen and (max-width: 656px) {
  .displ img {
    width: 7.5rem;
  }
}
@media only screen and (max-width: 652px) {
  .displ img {
    width: 7rem;
  }
}

@media only screen and (max-width: 560px) {
  .service h1 {
    font-size: 1.5rem;
  }
}
