.header {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 1rem;
}
.logo img {
  width: 5rem;
}
.logoone {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #c89116;
}
.logoone h2 {
  font-size: 2rem;
  background: linear-gradient(180deg, #c89116 0%, #422e00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
}

li {
  padding: 0rem 1.1rem;
  float: left;
  color: #c89116;
}

li a {
  color: #c89116;
  text-decoration: none;
  font-size: 1rem;
  opacity: 0.8;
  transition: background-size 0.5s ease-in-out;
  background-image: linear-gradient(#c89116, #c89116);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
}

li a.active,
li a:hover,
li a:focus {
  opacity: 1;
  background-size: 100% 0.1em;
}

#click {
  display: none;
}

.menu {
  display: none;
}
.full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  #eng {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .logoone h2 {
    display: none;
  }
}

@media only screen and (max-width: 616px) {
  li {
    padding: 1rem 1.1rem;
  }

  .menu {
    display: block;
    font-size: 1.5rem;
    color: #c89116;
    z-index: 11111111111;
    position: relative;
    padding-right: 1.5rem;
  }

  ul {
    background-color: #000000;
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 5rem;
    top: 0;
    left: -100%;
    z-index: 111111;
  }

  #click:checked ~ ul {
    left: 0%;
    transition: all 0.3s ease-in-out;
  }
  .logoone img {
    z-index: 1111111111111;
  }
  .menu {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 480px) {
  .logoone img {
    width: 4rem;
  }
}
