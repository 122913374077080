* {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans", sans-serif;
}
::-webkit-scrollbar {
  width: 0.7rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #423d00;
}
::-webkit-scrollbar-thumb:active {
  background: #423d00;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  background: #211e00;
}
::-webkit-scrollbar-corner {
  background: #000000;
}
body {
  background: linear-gradient(180deg, #191919 0%, #000000 100%);
  color: white;
}
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}
