.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
}
.overlayc {
  position: absolute;
  top: 0;
  opacity: 0.9;
  left: 0;
  width: 100%;
  height: 30rem;
  z-index: -1;
  background-image: url("../../assets/images/bgtwo.webp");
  background-size: cover;
  background-position: 50% 25%;
  color: white;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 3rem 3rem 3rem;
}
.buttonone {
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 0.4rem;
  padding: 1rem 3rem;
  transition: all 0.5s ease-in-out;
}
.buttonone:hover {
  background-color: white;
  color: black;
}
.head {
  font-style: italic;
  margin: 13rem 0 4rem 0;
}
.contactinfo {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.phoneone,
.phonetwo,
.phonethree {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-decoration: none;
  gap: 1rem;
  width: 30%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.phoneone:hover,
.phonetwo:hover,
.phonethree:hover {
  opacity: 0.8;
}
.linetwo {
  height: 10rem;
  border: 0.07rem solid #c89116;
  border-radius: 1rem;
}
.contactinfo i {
  font-size: 1.5rem;
}

.name {
  color: #c89116;
  font-size: 1.5rem;
}
.linkedin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  margin: 5rem 0;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
}
.linkedin:hover {
  opacity: 0.8;
}
.map {
  opacity: 0.8;
  background-position: 50% 25%;
}
.map img {
  width: 90rem;
  transition: all 0.3s ease-in-out;
}
.map img:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1286px) {
  .map img {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
}

@media (max-width: 930px) {
}

@media only screen and (max-width: 768px) {
  .content .head {
    font-size: 2rem;
  }
  .buttonone {
    font-size: 1.5rem;
  }
  .contactinfo {
    gap: 2.4rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 700px) {
}

@media only screen and (max-width: 616px) {
  .overlayc {
    height: 25rem;
  }
  .content {
    padding-top: 5rem;
  }
  .contactinfo {
    gap: 1.3rem;
    font-size: 0.7rem;
  }
  .name {
    font-size: 1.1rem;
  }
  .linkedin i {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .contactinfo {
    gap: 1rem;
    font-size: 0.5rem;
  }
  .name {
    font-size: 1rem;
  }
  .linetwo {
    height: 8rem;
  }
  .contactinfo i {
    font-size: 1.5rem;
  }
}

@media (max-width: 420px) {
  .content .head {
    font-size: 1.5rem;
  }
  .contactinfo {
    gap: 0.7rem;
    font-size: 0.4rem;
  }
  .name {
    font-size: 0.9rem;
  }
  .linetwo {
    height: 7rem;
  }
  .linkedin {
    font-size: 0.8rem;
  }
  .buttonone {
    font-size: 1rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 391px) {
  .contactinfo {
    gap: 0.4rem;
  }
  .contactinfo i {
    font-size: 1rem;
  }
}

@media screen and (max-width: 360px) {
}

@media (max-width: 350px) {
  .contactinfo {
    gap: 0.3rem;
  }
  .linetwo {
    height: 6.5rem;
  }
  .linkedin i {
    font-size: 1.5rem;
  }
  .content .head {
    font-size: 1.2rem;
  }
}

@media (max-width: 320px) {
}
