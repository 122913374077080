.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.7rem;
}
h1,
h3 {
  font-weight: 400;
}
.about h1 {
  font-size: 3.5rem;
  text-transform: uppercase;
}
.star {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.star i {
  color: #c89116;
}
.star h3 {
  margin-left: 0.5rem;
}
.boxes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}
.box {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(90deg, #d9d9d9 0%, #737373 100%);
  border-radius: 10px;
  padding: 2rem;
  color: black;
}
.position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.remote {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desc {
  text-align: justify;
  width: 50%;
  display: flex;
  align-items: center;
}
.apply {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-decoration: none;
  color: black;
}
.aboutus {
  border-top: 0.5rem solid #000000;
  border-bottom: 0.5rem solid #000000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 3rem;
  background: linear-gradient(90deg, #d9d9d9 0%, #737373 100%);
  color: #000000;
}
.linefour {
  margin-top: 5rem;
  border: 0.04rem solid darkgrey;
  width: 100%;
  border-radius: 0.2rem;
}
.values {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  gap: 1rem;
  height: 100vh;
}
.ptag {
  margin-bottom: 1rem;
}
p {
  text-align: justify;
}
h4 {
  width: 20%;
  background-color: #c89116;
  color: black;
  font-size: 1.4rem;
  padding: 0.4rem 0.7rem;
  border-radius: 0.1rem;
}
.onep {
  font-size: 2rem;
}
/* .team {
  padding: 0 3rem;
  height: 100vh;
  gap: 1rem;
  display: flex;
  flex-direction: column;
} */
.overthree {
  background-image: url("../../assets/images/team.webp");
  background-size: cover;
  background-position: 50% 25%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25rem;
  z-index: -1;
}
.headabout {
  margin: 8rem 0 5rem 3rem;
  font-weight: 600;
}
/* .tboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  align-items: center;
} */
/* .tbox {
  height: 27rem;
  width: 20rem;
  background-color: black;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column-reverse;
} */
/* .bei {
  width: 100%;
  background: linear-gradient(180deg, #e9e5dc 0%, #83817c 100%);
  height: 70%;
  border-radius: 0 0 0.4rem 0.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: black;
} */
/* .tbox img {
  width: 80%;
  margin-top: -5rem;
  height: 15rem;
  margin-bottom: 1.5rem;
}
.named {
  font-size: 1.45rem;
  margin-bottom: 1rem;
} */

.legal {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 1rem;
}
.legalImg {
  display: flex;
  gap: 1rem;
}
.legalImg img {
  width: 20rem;
}

@media only screen and (max-width: 1286px) {
  .values {
    margin-bottom: 2.5rem;
  }
  .tag {
    font-size: 1rem;
  }
  .about h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1024px) {
  .values {
    margin-bottom: 2.8rem;
  }
  .tag {
    font-size: 0.8rem;
  }
  .about h1 {
    font-size: 2.6rem;
  }
  .ptag {
    font-size: 1rem;
  }
  .overthree {
    height: 22rem;
  }
  .headabout {
    margin-bottom: 1.3rem;
  }
}

@media (max-width: 930px) {
  .about h1 {
    font-size: 2.2rem;
  }
  .tag {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .desc {
    width: 100%;
  }
  .about h1 {
    font-size: 2rem;
  }
  .ptag {
    font-size: 0.7rem;
  }
  .tag {
    font-size: 0.6rem;
  }
  .values {
    margin-bottom: -8rem;
  }
  .legalImg img {
    width: 18rem;
  }
}

@media screen and (max-width: 700px) {
  .about h1 {
    font-size: 1.8rem;
  }
  h4 {
    width: 25%;
  }
  .legalImg img {
    width: 16rem;
  }
}

@media only screen and (max-width: 616px) {
  .values {
    margin-bottom: -5rem;
  }
  .overthree {
    height: 18rem;
  }
  .headabout {
    margin-top: 4.5rem;
  }
  .about h1 {
    font-size: 1.6rem;
  }
  .legalImg img {
    width: 14rem;
  }
}
@media only screen and (max-width: 560px) {
  .tag {
    font-size: 0.5rem;
  }
  .legalImg img {
    width: 12rem;
  }
}
@media only screen and (max-width: 493px) {
  .values {
    margin-bottom: -3rem;
  }
}
@media only screen and (max-width: 480px) {
  .box {
    padding: 1.4rem;
  }
  .tag {
    padding: 0.4rem;
    width: 30%;
  }
  .about h1 {
    font-size: 1.4rem;
  }
  .legalImg img {
    width: 10rem;
  }
}
@media (max-width: 443px) {
  .values {
    margin-bottom: 0;
  }
}

@media (max-width: 420px) {
  .about h1 {
    font-size: 1.1rem;
  }
  .ptag {
    font-size: 0.6rem;
  }
  .values {
    margin-bottom: -5rem;
  }
  .tag {
    width: 33%;
  }
  .legalImg img {
    width: 8rem;
  }
}

@media (max-width: 391px) {
}

@media screen and (max-width: 360px) {
  .values {
    margin-bottom: -5rem;
  }
  .tag {
    font-size: 0.4rem;
  }
  .aboutus,
  .values {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .headabout {
    margin-left: 2rem;
  }
  .about h1 {
    font-size: 1rem;
  }
  .legalImg img {
    width: 6rem;
  }
}

@media (max-width: 350px) {
}

@media (max-width: 320px) {
}
